import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    fetchPurches(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios.get('/api/v1/get-procurment-requests-need-approve', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    FollowfetchPurches(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios.get('/api/v1/purchase-tracking', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    sendResponse(ctx, approvestate) {
      return new Promise((resolve, reject) => {
        const data = {
          approve: approvestate.approvestate,
          notes: approvestate.notes,
        }

        const url = `/api/v1/approve-procurment-request/${approvestate.id}`
        axios
          .post(url, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
