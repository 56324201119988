import { render, staticRenderFns } from "./follow-up-purchase-request.vue?vue&type=template&id=221cac1b&scoped=true&"
import script from "./follow-up-purchase-request.vue?vue&type=script&lang=js&"
export * from "./follow-up-purchase-request.vue?vue&type=script&lang=js&"
import style0 from "./follow-up-purchase-request.vue?vue&type=style&index=0&id=221cac1b&scoped=true&lang=css&"
import style1 from "./follow-up-purchase-request.vue?vue&type=style&index=1&id=221cac1b&lang=scss&scoped=true&"
import style2 from "./follow-up-purchase-request.vue?vue&type=style&index=2&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "221cac1b",
  null
  
)

export default component.exports